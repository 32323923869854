$primary: #cb9905;
$secondary: #e6ca8e;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);
@import "~bootstrap/scss/bootstrap";

.btn-primary {
  color: #ffffff;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Black.woff2") format("woff2"),
    url("../fonts/Avenir-Black.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/HelveticaNeue-Medium.woff2") format("woff2"),
    url("../fonts/HelveticaNeue-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: HelveticaNeue, Arial, Helvetica, sans-serif;
  color: #222a55;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #222a55;
  margin-bottom: 1rem;
}

.bg-gray-100 {
  background-color: #f8f9fa;
}

.app-center {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.validation-error {
  color: red;
}

.powerbi-frame iframe {
  border: none;
}

.swal-button {
  background-color: #01689a;
}

.swal-button--danger {
  background-color: #cf423b;
}

.swal-button--cancel {
  background-color: #d7d7d7;
}

.swal-client-button {
  font-weight: 400;
  padding: 5px 10px;
  width: 100%;
}

.swal-list > .swal-footer {
  display: flex;
  flex-direction: column;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  z-index:1;
}
